import React from "react";
import icon2 from "../assests/images/Microsoft-Dynamics-365-Logo.webp";
import icon1 from "../assests/images/sap.webp";
import icon3 from "../assests/images/pastal.jpeg";
import leftimg from "../assests/images/left-image-4.png";
import { BiBookmarkAlt } from "react-icons/bi";
import { GiBookAura, GiProgression } from "react-icons/gi";
import { SiDynamics365 } from "react-icons/si";

export const Products = () => {
  const products = [
    {
      title: "SAP S/4HANA",
      brief_description:
        "A comprehensive cloud ERP solution designed for businesses of all sizes, integrating various functionalities to optimize operations.",
      icon: <GiProgression size={100} color="white" />,
      img: icon1,
    },
    {
      title: "Microsoft Dynamics 365 Business Applications",
      brief_description:
        "Microsoft Dynamics 365 is a comprehensive suite of business applications that integrates CRM and ERP, enhancing efficiency through AI-driven insights for sales, service, finance, and supply chain management.",
      icon: <SiDynamics365 size={100} color="white" />,
      img: icon2,
    },
    {
      title: "PASTEL eLearning Management Solution",
      brief_description:
        "This platform collectively address various training needs, from small businesses to large-scale enterprises, ensuring flexibility and scalability for corporate training initiatives.",
      icon: <GiBookAura size={100} color="white" />,
      img: icon3,
    },
  ];
  
  return (
    <section className="section" id="products">
      <div className="container" style={{ padding: "100px 0 0 0", position: "relative", zIndex: "9" }}>
        <div className="col-lg-8 offset-lg-2">
          <div className="center-heading">
            <h2>
              Our <em>Products</em>
            </h2>
            <p>
              We take pride in offering a wide range of high-quality products,
              designed to meet your needs and exceed expectations.
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="left-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big"
            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
          >
            <img
              src={leftimg}
              className="rounded img-fluid d-block mx-auto"
              alt="App"
              style={{
                scale: "1.1",
              }}
            />
          </div>
          <div className="right-text offset-lg-1 col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix">
            <ul>
              {products.map((product, index) => (
                <li key={index}>
                  <div className="d-flex align-items-center mb-5">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "auto",
                        height: "auto",
                        borderRadius: "10%",
                        // backgroundColor: "#5A8CB1",
                        marginRight: "20px",
                        padding: "10px",
                      }}
                    >
                      <img src={product.img} alt="icon" 
                      style={{ width: "110px" }} 
                      />
                    </div>
                    <div className="text">
                      <h4 style={product.title === "SAP S/4HANA" ? { letterSpacing: "-0.5px" } : {}}>
                        {product.title}
                      </h4>
                      <p>{product.brief_description}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
